import React, { useEffect } from 'react'
import Appointments from './Appointments/Appointments'
import PractitionerSearch from './PractitionerSearch/PractitionerSearch'
import Services from './Services/Services'
import Time from './Time/Time'
import LocationSearch from './LocationSearch/LocationSearch'
import Type from './Type/Type'
import Style from './Search.module.css'
import { useDispatch } from 'react-redux'
import { iniateLoad } from '../../../state/search/search.actions'
import { Route, useRouteMatch } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

const Search: React.FC = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const routes = [
    {
      path: `${match?.path}/:view/practitioner`,
      Component: PractitionerSearch,
      popup: false
    },
    {
      path: `${match?.path}/location`,
      Component: LocationSearch,
      popup: false
    },
    {
      path: `${match?.path}/services`,
      Component: Services,
      popup: true
    },
    {
      path: `${match?.path}/time`,
      Component: Time,
      popup: true
    },
    {
      path: `${match?.path}/type`,
      Component: Type,
      popup: true
    },
  ] as const

  const getTransitionClassNames = (popup: boolean) =>
    popup ?
      {
        enter: Style.popupEnter,
        enterActive: Style.popupEnterActive,
        exit: Style.popupExit,
        exitActive: Style.popupExitActive
      }
      :
      {
        enter: Style.pageEnter,
        enterActive: Style.pageEnterActive,
        exit: Style.pageExit,
        exitActive: Style.pageExitActive
      }

  useEffect(() => {
    dispatch(iniateLoad())
  }, [dispatch])

  function renderRoute({ path, popup, Component }: { path: string, popup: boolean, Component: typeof routes[number]['Component'] }, index: number) {
    return <Route key={path} exact path={path}>
      {({ match: routematch }) => (
        <CSSTransition
          in={routematch != null}
          timeout={150}
          classNames={getTransitionClassNames(popup)}
          unmountOnExit
        >
          <div className={Style.page}>
            <Component />
          </div>
        </CSSTransition>
      )}
    </Route>
  }

  return (
    <div className={Style.container}>
      <Appointments />
      {
        routes
          .filter(route => !route.popup)
          .map(renderRoute)
      }
      {
        routes
          .filter(route => route.popup)
          .map(renderRoute)
      }
    </div>
  )
}

export default Search
