import React from 'react'
import { TimeOfDay } from '../../../../types'
import { Translate } from 'react-localize-redux'
import { setHours, format } from 'date-fns'
import { locales } from '../../../../locale/dateLocales'
import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

const StyledContainer = styled.div`
  padding: 15px 5px;
`

const StyledTitle = styled.div`
  color: ${props => props.theme.primaryText};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
`

const StyledSelections = styled.div`
  display: flex;
`

const NoTimesMixin = css`
  color: ${props => props.theme.secondaryText};
  background: ${props => props.theme.borderColor};
  border: 2px solid ${props => props.theme.borderColor};
`

const ActiveMixin = css`
  color: ${props => props.theme.invertText};
  background: ${props => props.theme.selectionColor};
  border: 2px solid ${props => props.theme.selectionColor};
`

const PendingMixin = css`
  animation-duration: 1s;
  animation-name: pendingAnimation;
  animation-iteration-count: infinite;

  @keyframes pendingAnimation {
    from {
      background: rgba(0, 0, 0, 0);
      border: 2px solid rgba(0, 0, 0, 0);
    }

    to {
      background: rgba(0, 0, 0, 0.1);
      border: 2px solid rgba(0, 0, 0, 0.1);
    }
  }
`

const PendingMixinActive = css`
  animation-duration: 1s;
  animation-name: pendingAnimationActive;
  animation-iteration-count: infinite;

  @keyframes pendingAnimationActive {
    from {
      background: ${props => props.theme.selectionColor};
      border: 2px solid ${props => props.theme.selectionColor};
    }

    to {
      background: ${props => transparentize(0.3, props.theme.selectionColor)};
      border: 2px solid ${props => transparentize(0.3, props.theme.selectionColor)};
    }
  }
`

const StyledTimeButton = styled.button<{ all?: boolean, active?: boolean, noTimes?: boolean, pending?: boolean }>`
  flex: ${props => props.all ? 0 : 1};
  min-height: 40px;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.borderColor};
  margin-right: 2.5px;
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.selectionColor};
  transition: all 150ms ease-out;

  &:last-of-type {
    margin-right: 0;
  }

  ${props => props.noTimes && NoTimesMixin}
  ${props => props.active && ActiveMixin}
  ${props => props.pending && !props.active && PendingMixin}
  ${props => props.pending && props.active && PendingMixinActive}
`

const StyledRange = styled.div<{ active?: boolean }>`
  font-size: 11px;
  font-weight: bold;
  color: ${props => props.active ? props.theme.invertText : props.theme.secondaryText};
`

interface Props {
  time: TimeOfDay
  onTimeChange: (time: TimeOfDay) => void
  pending: boolean
  slots: { [x: string]: boolean; }
}

const TimeSelector: React.FC<Props> = (props) => (
  <StyledContainer>
    <StyledTitle><Translate id="calendar.timeOfDayTitle" /></StyledTitle>
    <Translate>
      {
        ({ activeLanguage }) =>
          <StyledSelections>
            <StyledTimeButton
              onClick={() => props.onTimeChange(TimeOfDay.All)}
              all={true}
              active={props.time === TimeOfDay.All}
              noTimes={!props.slots[TimeOfDay.All]}
              pending={props.pending}
            >
              <Translate id="calendar.timeOfDayAll" />
            </StyledTimeButton>
            <StyledTimeButton
              onClick={() => props.onTimeChange(TimeOfDay.Morning)}
              active={props.time === TimeOfDay.Morning}
              noTimes={!props.slots[TimeOfDay.Morning]}
              pending={props.pending}
            >
              <Translate id="calendar.timeOfDayMorning" />
              <StyledRange active={props.time === TimeOfDay.Morning}>
                {format(setHours(new Date(2020, 1, 1, 0, 0, 0 , 0), 0), 'h aaaa', {locale: locales[activeLanguage?.code]})}
                –
                {format(setHours(new Date(2020, 1, 1, 0, 0, 0 , 0), 12), 'h aaaa', {locale: locales[activeLanguage?.code]})}
              </StyledRange>
            </StyledTimeButton>
            <StyledTimeButton
              onClick={() => props.onTimeChange(TimeOfDay.Afternoon)}
              active={props.time === TimeOfDay.Afternoon}
              noTimes={!props.slots[TimeOfDay.Afternoon]}
              pending={props.pending}
            >
              <Translate id="calendar.timeOfDayAfternoon" />
              <StyledRange active={props.time === TimeOfDay.Afternoon}>
                {format(setHours(new Date(2020, 1, 1, 0, 0, 0 , 0), 12), 'h aaaa', {locale: locales[activeLanguage?.code]})}
                –
                {format(setHours(new Date(2020, 1, 1, 0, 0, 0 , 0), 18), 'h aaaa', {locale: locales[activeLanguage?.code]})}
              </StyledRange>
            </StyledTimeButton>
            <StyledTimeButton
              onClick={() => props.onTimeChange(TimeOfDay.Evening)}
              active={props.time === TimeOfDay.Evening}
              noTimes={!props.slots[TimeOfDay.Evening]}
              pending={props.pending}
            >
              <Translate id="calendar.timeOfDayEvening" />
              <StyledRange active={props.time === TimeOfDay.Evening}>
                {format(setHours(new Date(2020, 1, 1, 0, 0, 0 , 0), 18), 'h aaaa', {locale: locales[activeLanguage?.code]})}
                –
                {format(setHours(new Date(2020, 1, 1, 0, 0, 0 , 0), 24), 'h aaaa', {locale: locales[activeLanguage?.code]})}
              </StyledRange>
            </StyledTimeButton>
          </StyledSelections>
      }
    </Translate>
  </StyledContainer>
)

export default TimeSelector
