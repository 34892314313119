import { Reservation, TimeOfDay, TypeInfoMapping } from '../types'
import {
  faHospital,
  faCommentsAlt,
  faPhone
} from '@fortawesome/pro-light-svg-icons'
import * as Firebase from './firebase'
import * as Timeslot from './timeslot'

export { Firebase }
export { Timeslot }

export const TimeOfDayMapping = {
  [TimeOfDay.All]: {min: 0, max: 24},
  [TimeOfDay.Morning]: {min: 0, max: 12},
  [TimeOfDay.Afternoon]: {min: 12, max: 18},
  [TimeOfDay.Evening]: {min: 18, max: 24}
}


export const typeInfoMapping: ReadonlyArray<TypeInfoMapping> = [
  {
    id: 1,
    icon: faHospital,
    title: 'recipient.typeInfo.appointment.title',
    subtitle: '',
    remote: false,
  },
  {
    id: 4,
    icon: faCommentsAlt,
    title: 'recipient.typeInfo.chat.title',
    subtitle: 'recipient.typeInfo.chat.subtitle',
    remote: true
  },
  {
    id: 5,
    icon: faPhone,
    title: 'recipient.typeInfo.phone.title',
    subtitle: 'recipient.typeInfo.phone.subtitle',
    remote: true
  }
]

export function getReservedAppointmentInfo(r: Reservation): TypeInfoMapping | undefined {
  if (r.isExternalObject) {
    return   { // 'external' appointments have 0 id
      id: 0,
      icon: faHospital,
      title: 'recipient.typeInfo.appointment.title',
      subtitle: '',
      remote: false,
      }
  }
  return typeInfoMapping.find(m => m.id === r.reservation?.timeslotTypeId)
}

export function assertNever(_value: never): never {
  throw new Error('not gonna happen')
}

export function assertNeverWith<T>(_value: never, returnValue: T): T {
  return returnValue
}
