import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { Translate } from 'react-localize-redux'
import {
  useLocation,
  useHistory
} from 'react-router-dom'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.accentColor};
  padding: 10px;
`

const StyledButton = styled.button`
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  text-align: left;
  padding: 0;
`

const StyledInputContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  height: 34px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 17px;
  transition: width 2s;
`

const StyledPlaceholder = styled.div`
  flex: 1;
  color: ${props => props.theme.invertText};
  margin: 0 5px;
  font-size: 15px;
`

const StyledIcon = styled.div`
  display: flex;
  color: ${props => props.theme.invertText};
`

const SearchBarButton: React.FC = () => {
  const location = useLocation()
  const history = useHistory()

  return (
    <StyledContainer>
      <StyledButton onClick={_ => history.replace(`${location.pathname}/practitioner${location.search}`)}>
        <StyledInputContainer>
          <StyledIcon>
            <FontAwesomeIcon
              icon={faSearch}
              style={{
                width: 19,
                height: 19
              }}
            />
          </StyledIcon>
          <StyledPlaceholder>
            <Translate id="header.searchPlaceholder" />
          </StyledPlaceholder>
        </StyledInputContainer>
      </StyledButton>
    </StyledContainer>
  )
}

export default SearchBarButton
