import { createAction, createAsyncAction } from 'typesafe-actions'
import { PractitionerDetails, SearchParams, Appointment, PersonalInformation, LocationDetails, CalendarSlot, ReservationParams, PractitionerLanguage, PractitionerPatientGroup } from '../../types'

export const iniateLoad = createAction('recipient/INITIAL_LOAD')<void>()

export const changeDate = createAction('recipient/CHANGE_DATE')<Date>()

export const changeActiveMonth = createAction('recipient/CHANGE_ACTIVE_MONTH')<Date>()

export const changePractitioner = createAction('recipient/CHANGE_PRACTITIONER')<number>()

export const changeAppointment = createAction('recipient/CHANGE_APPOINTMENT')<number>()

export const changeLocation = createAction('recipient/CHANGE_LOCATION')<number>()

export const changeReschedule = createAction('recipient/CHANGE_RESCHEDULE')<number | null>()

export const changeService = createAction('recipient/CHANGE_SERVICE')<number | null>()

export const changeType = createAction('recipient/CHANGE_TYPE')<number | null>()

export const getPractitionerDetails = createAsyncAction(
  'recipient/GET_PRACTITIONER_REQUEST',
  'recipient/GET_PRACTITIONER_SUCCESS',
  'recipient/GET_PRACTITIONER_FAILURE'
)<number, [PractitionerDetails, PractitionerLanguage[], PractitionerPatientGroup[]], Error>()

export const getPractitionerServices = createAsyncAction(
  'recipient/GET_PRACTITIONER_SERVICES_REQUEST',
  'recipient/GET_PRACTITIONER_SERVICES_SUCCESS',
  'recipient/GET_PRACTITIONER_SERVICES_FAILURE'
)<number, number[], Error>()

export const doSearch = createAsyncAction(
  'recipient/DO_SEARCH_REQUEST',
  'recipient/DO_SEARCH_SUCCESS',
  'recipient/DO_SEARCH_FAILURE'
)<SearchParams, Appointment[], Error>()

export const doCalendarSearch = createAsyncAction(
  'recipient/DO_CALENDAR_SEARCH_REQUEST',
  'recipient/DO_CALENDAR_SEARCH_SUCCESS',
  'recipient/DO_CALENDAR_SEARCH_FAILURE'
)<SearchParams, CalendarSlot[], Error>()

export const getPerson = createAsyncAction(
  'recipient/GET_PERSON_REQUEST',
  'recipient/GET_PERSON_SUCCESS',
  'recipient/GET_PERSON_FAILURE'
)<void, PersonalInformation, Error>()

export const getLocation = createAsyncAction(
  'recipient/GET_LOCATION_REQUEST',
  'recipient/GET_LOCATION_SUCCESS',
  'recipient/GET_LOCATION_FAILURE'
)<number, LocationDetails, Error>()

export const doReservation = createAsyncAction(
  'recipient/DO_RESERVATION_REQUEST',
  'recipient/DO_RESERVATION_SUCCESS',
  'recipient/DO_RESERVATION_FAILURE'
)<ReservationParams, void, Error>()

export const doReschedule = createAsyncAction(
  'recipient/DO_RESCHEDULE_REQUEST',
  'recipient/DO_RESCHEDULE_SUCCESS',
  'recipient/DO_RESCHEDULE_FAILURE'
)<{ reservation: ReservationParams, rescheduleId: number }, ReservationParams, Error>()

export const getRescheduleAppointment = createAsyncAction(
  'recipient/GET_RESCHEDULE_APPOINTMENT_REQUEST',
  'recipient/GET_RESCHEDULE_APPOINTMENT_SUCCESS',
  'recipient/GET_RESCHEDULE_APPOINTMENT_FAILURE'
)<number, Appointment, Error>()
