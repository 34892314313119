import { IconDefinition } from '@fortawesome/pro-light-svg-icons'

export interface I18n {
  [k: string]: string
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export interface FirebaseAnalytics {
  logEvent: (event: string, payload: unknown) => void
}

export interface TypeInfoMapping {
  id: number
  icon: IconDefinition
  title: string
  subtitle: string
  remote: boolean
}

export interface User {
  id: number
}

export * from './search'
export * from './recipient'
