import React, { useState } from 'react'
import style from './ObjectPicture.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMd, faDiagnoses } from '@fortawesome/pro-light-svg-icons'
import { ObjectType } from '../../../types/search'

interface Props {
  objectId: number
  objectType: ObjectType
}

const ObjectPicture: React.FC<Props> = ({ objectId, objectType }) => {
  const [ noImage, setNoImage ] = useState<boolean>(false)

  if (objectType === 'resource') {
    return (
      <FontAwesomeIcon
        color="#FFFFFF"
        size="2x"
        icon={faDiagnoses}
      />
    )
  }
  return (
    noImage ?
      <FontAwesomeIcon
        color="#FFFFFF"
        size="2x"
        icon={faUserMd}
      />
      :
      <img
        onError={_ => setNoImage(true)}
        className={style.image}
        src={`${process.env.REACT_APP_PRACTITIONER_API}/v1/public/practitioner/${objectId}/photo`}
        alt=""
      />
  )
}

export default ObjectPicture
