import React, { useEffect } from 'react'
import Style from './Appointments.module.css'
import AppointmentRow from './AppointmentRow/AppointmentRow'
import ReservationRow from './ReservationRow/ReservationRow'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../state'
import Loader from '../../../general/Loader/Loader'
import { format, parseJSON } from 'date-fns'
import { Translate } from 'react-localize-redux'
import Header from '../Header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarTimes, faCalendar } from '@fortawesome/pro-light-svg-icons'
import { locales } from '../../../../locale/dateLocales'
import cn from 'classnames'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import Terms from './Terms/Terms'
import { getFutureReservations, getPastReservations } from '../../../../state/search/search.selectors'
import { stringify } from 'query-string'
import { GroupedAppointment } from '../../../../types'
import { goToDigitalClinic } from '../../../../services/native'
import { timer } from 'rxjs'
import { tap } from 'rxjs/operators'
import { getReservations } from '../../../../state/search/search.actions'

const generateRecipientUrl = (appointment: GroupedAppointment, service: number | null, type: number | null) => {
  const date = parseJSON(appointment.appointments[0].start)

  const url = {
    date: format(date, 'dd-MM-yyyy'),
    time: format(date, 'HH:mm'),
    ...(service !== null && { service }),
    ...(type !== null && { type })
  }

  return `/recipient/${appointment.objectId}?${stringify(url)}`
}

const DigitalClinicLink: React.FC<{id: string}> = ({id}) => {
  return <button className={Style.emptyStateLink} onClick={goToDigitalClinic}><Translate id={id}/></button>
}

const Appointments = () => {
  const date = useSelector((state: RootState) => state.search.terms.date)
  const service = useSelector((state: RootState) => state.search.terms.service)
  const type = useSelector((state: RootState) => state.search.terms.type)
  const types = useSelector((state: RootState) => state.general.types.data)
  const appointments = useSelector((state: RootState) => state.search.appointments.data)
  const alternativeAppointments = useSelector((state: RootState) => state.search.alternativeAppointments.data)
  const alternativeDate = useSelector((state: RootState) => state.search.alternativeDate.data)
  const appointmentsPending = useSelector((state: RootState) => state.search.appointments.status === 'PENDING')
  const alternativeAppointmentsPending = useSelector((state: RootState) => state.search.alternativeAppointments.status === 'PENDING' || state.search.alternativeDate.status === 'PENDING')

  return (
    <div>
      <Terms />
      {
        appointmentsPending ?
          <Loader />
          :
          appointments.length && typeof type !== 'undefined' ?
            <>
              <h1 className={Style.title}><Translate id="appointments.title" /></h1>
              <Translate>
                {
                  ({ activeLanguage }) =>
                    <p className={Style.subtitle}>{format(date, 'PP', {locale: locales[activeLanguage?.code]})}</p>
                }
              </Translate>
              <div className={Style.box}>
                {
                  appointments.map((appointment) =>
                    <AppointmentRow
                      key={appointment.objectId}
                      name={appointment.practitioner}
                      medicalService={appointment.medicalService}
                      objectId={appointment.objectId}
                      objectType={appointment.objectType}
                      location={appointment.location}
                      times={appointment.appointments}
                      price={appointment.price}
                      link={generateRecipientUrl(appointment, service, type)}
                      selectedType={type}
                      types={types}
                    />
                  )
                }
              </div>
            </>
            :
            <div className={Style.emptyState}>
              <FontAwesomeIcon
                icon={faCalendarTimes}
                size="2x"
                color="#7f7f7f"
              />
              <h2 className={Style.emptyStateTitle}><Translate id="appointments.emptyTitle" /></h2>
              <p>
                <Translate id="appointments.emptySubtitle" data={{link1: <DigitalClinicLink id="appointments.emptySubtitleLink1"/>, link2: <DigitalClinicLink id="appointments.emptySubtitleLink2"/>}}/>
              </p>
            </div>
      }
      {
        appointments.length === 0 &&
        !appointmentsPending ?
          alternativeAppointmentsPending ?
            <Loader />
            :
            alternativeAppointments.length > 0 &&
            typeof type !== 'undefined' &&
              <div className={Style.alternativeAppointments}>
                <h1 className={Style.title}><Translate id="appointments.alternativeTitle" /></h1>
                <Translate>
                  {
                    ({ activeLanguage }) =>
                      <p className={Style.subtitle}>{format(alternativeDate, 'PP', {locale: locales[activeLanguage?.code]})}</p>
                  }
                </Translate>
                <div className={Style.box}>
                  {
                    alternativeAppointments.map((appointment, index) =>
                      <AppointmentRow
                        key={`appointment-${index}`}
                        name={appointment.practitioner}
                        medicalService={appointment.medicalService}
                        objectId={appointment.objectId}
                        objectType={appointment.objectType}
                        location={appointment.location}
                        times={appointment.appointments}
                        price={appointment.price}
                        link={generateRecipientUrl(appointment, service, type)}
                        selectedType={type}
                        types={types}
                      />
                    )
                  }
                </div>
              </div>
          :
          null
      }
    </div>
  )
}

const refreshInterval = 30 /* sec */ * 1000
const timerObservable = timer(refreshInterval, refreshInterval)

const Reservations = () => {
  const dispatch = useDispatch()
  const searchState = useSelector((state: RootState) => state.search)
  const pending = useSelector((state: RootState) => state.search.reservations.status === 'PENDING')
  const futureReservations = getFutureReservations(searchState)
  const pastReservations = getPastReservations(searchState)
  const reservationsFound = futureReservations.length || pastReservations.length

  // Pipes for reservation refresh
  useEffect(() => {
    const timerSubscription = timerObservable
      .pipe(tap(() => dispatch(getReservations.request(true))))
      .subscribe()

    return () => {
      timerSubscription.unsubscribe()
    }
  }, [dispatch])

  return (
    <div>
      {
        pending ?
          <Loader />
          :
          <>
            {
              reservationsFound ?
                <>
                  {
                    futureReservations.length ?
                      <>
                        <h1 className={cn(Style.title, Style['title--no-subtitle'])}><Translate id="reservations.upcomingTitle" /></h1>
                        <div className={cn(Style.box, Style.reservations)}>
                          {futureReservations.map((reservation) =>
                            <ReservationRow
                              key={reservation.timeslotId}
                              reservation={reservation}
                            />
                          )}
                        </div>
                      </>
                      :
                      null
                  }
                  {
                    pastReservations.length ?
                      <>
                        <h1 className={cn(Style.title, Style['title--no-subtitle'])}><Translate id="reservations.pastTitle" /></h1>
                        <div className={cn(Style.box, Style.reservations)}>
                          {pastReservations.map((reservation) =>
                            <ReservationRow
                              key={reservation.timeslotId}
                              reservation={reservation}
                            />
                          )}
                        </div>
                      </>
                      :
                      null
                  }
                </>
                :
                <div className={Style.emptyState}>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    size="2x"
                    color="#7f7f7f"
                  />
                  <h2 className={Style.emptyStateTitle}><Translate id="reservations.emptyTitle" /></h2>
                </div>
            }
          </>
      }
    </div>
  )
}

const Container = () => {
  const match = useRouteMatch()

  return (
    <div className={Style.container}>
      <Header />
      <div className={Style.scroller}>
        <div className={Style.content}>
          <Switch>
            <Route path={`${match?.path}/appointments`}>
              <Appointments />
            </Route>
            <Route path={`${match?.path}/reservations`}>
              <Reservations />
            </Route>
            <Redirect exact from={match?.path} to={`${match?.path}/appointments`} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default Container
