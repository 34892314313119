import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../state'
import { Translate } from 'react-localize-redux'
import { format, isSameDay } from 'date-fns'
import styled from 'styled-components'

const StyledContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 5px;
`

const StyledTerm = styled.button`
  display: flex;
  flex: 1 0 auto;
  margin: 0 5px 10px 5px;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 5px;
  overflow: hidden;
  background: ${props => props.theme.contentBackground};
  border-radius: 4px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
`

const StyledTermLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  color: ${props => props.theme.primaryText};
  font-weight: 600;
  font-size: 11px;
`

const Terms = () => {
  const history = useHistory()
  const location = useLocation()
  const services = useSelector((state: RootState) => state.general.services.data)
  const selectedServiceId = useSelector((state: RootState) => state.search.terms.service)
  const selectedService = services.find(service => service.medicalServiceId === selectedServiceId)
  const date = useSelector((state: RootState) => state.search.terms.date)
  const locationDetails = useSelector((state: RootState) => state.search.terms.location[0])
  const types = useSelector((state: RootState) => state.general.types.data)
  const selectedTypeId = useSelector((state: RootState) => state.search.terms.type)
  const selectedType = types.find(type => type.timeslotTypeId === selectedTypeId)

  return (
    <StyledContainer>
      <StyledTerm onClick={_ => history.replace(`/search/type${location.search}`)}>
        <StyledTermLabel>
          <Translate>
            {
              ({ activeLanguage }) =>
                selectedType ?
                  <StyledTermLabel>{selectedType.timeslotTypeName[activeLanguage.code]}</StyledTermLabel>
                  :
                  <StyledTermLabel><Translate id="type.all" /></StyledTermLabel>
            }
          </Translate>
        </StyledTermLabel>
      </StyledTerm>
      <StyledTerm onClick={_ => history.replace(`/search/location${location.search}`)}>
        <StyledTermLabel>{ locationDetails.name }</StyledTermLabel>
      </StyledTerm>
      <StyledTerm onClick={_ => history.replace(`/search/time${location.search}`)}>
        <StyledTermLabel>
          { isSameDay(date, new Date()) ? <Translate id="general.today" /> : format(date, 'd.L.') }
        </StyledTermLabel>
      </StyledTerm>
      <StyledTerm onClick={_ => history.replace(`/search/services${location.search}`)}>
        <Translate>
          {
            ({ activeLanguage }) =>
              selectedServiceId ?
                <StyledTermLabel>{selectedService?.medicalServiceName[activeLanguage.code]}</StyledTermLabel>
                :
                <StyledTermLabel><Translate id="services.all" /></StyledTermLabel>
          }
        </Translate>
      </StyledTerm>
    </StyledContainer>
  )
}

export default Terms
