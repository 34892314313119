import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state'
import Loader from '../../../general/Loader/Loader'
import { format, parseJSON } from 'date-fns'
import { Appointment, AppointmentPrice } from '../../../../types'
import { Translate } from 'react-localize-redux'
import styled, { css, ThemeContext } from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 0 20px;
`

const ActiveMixin = css`
  background: ${props => props.theme.selectionColor};
  border-color: ${props => props.theme.selectionColor};
  color: ${props => props.theme.invertText};
`

const StyledAppointment = styled.div<{ active?: boolean }>`
  flex: 0 1;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  color: ${props => props.theme.selectionColor};
  text-align: center;
  transition: all 150ms ease-out;

  ${props => props.active && ActiveMixin}
`

const StyledDuration = styled.span<{ active?: boolean }>`
  display: block;
  color: ${props => props.active ? props.theme.invertText : props.theme.secondaryText};
  font-size: 11px;
`

const StyledEmpty = styled.div`
  font-weight: bold;
  color: ${props => props.theme.primaryText};
  text-align: center;
  width: 100%;
`

interface Props {
  activeType: number
  activeService: number
  activeAppointment: number | null
  onSetAppointmentAndPrice: (appointment: Appointment, price: AppointmentPrice) => void
}

const Appointments: React.FC<Props> = (props) => {
  const appointments = useSelector((state: RootState) => state.recipient.appointments.data)
  const pending = useSelector((state: RootState) => state.recipient.appointments.status === 'PENDING')
  const theme = useContext(ThemeContext)

  return (
    <StyledContainer>
      {
        pending ?
          <Loader size="1x" color={theme.selectionColor} />
          :
          appointments?.length ?
            appointments.map((appointment, index) => {
              const appropriatePrice = appointment.prices.find(price => price.medicalServiceId === props.activeService && price.timeslotTypeId === props.activeType)

              return (
                appropriatePrice ?
                  <StyledAppointment
                    active={props.activeAppointment === appointment.timeslotId}
                    onClick={() => props.onSetAppointmentAndPrice(appointment, appropriatePrice)}
                    key={appointment.timeslotId}
                  >
                    {format(parseJSON(appointment.start), 'HH:mm')}
                    <StyledDuration active={props.activeAppointment === appointment.timeslotId}>{appointment.duration} min</StyledDuration>
                  </StyledAppointment>
                  :
                  null
              )
            })
            :
            <StyledEmpty><Translate id="recipient.noAppointments" /></StyledEmpty>
      }
    </StyledContainer>
  )
}

export default Appointments
