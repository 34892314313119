import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../state'
import { changeDate, changeTimeOfDay } from '../../../../state/search/search.actions'
import Popup from '../../../general/Popup/Popup'
import { Translate } from 'react-localize-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Calendar from '../../../general/Calendar/Calendar'
import * as api from '../../../../services/api'
import { from, Subject } from 'rxjs'
import { switchMap, tap, debounceTime } from 'rxjs/operators'
import { SearchTerms, CalendarSlot, Appointment, TimeOfDay } from '../../../../types'
import { setDate } from 'date-fns'
import TimeSelector from './TimeSelector'
import { countTimeSlotsForTimeOfDay, getSlotsForTimesOfDay, getStartAndEndDateForTimeOfDay } from './Utilies'

const daySearchSubject = new Subject<SearchTerms>()
const calendarSearchSubject = new Subject<SearchTerms>()

const Time: React.FC<RouteComponentProps> = (props) => {
  // Store variables
  const dispatch = useDispatch()
  const storeDate = useSelector((state: RootState) => state.search.terms.date)
  const storeTimeOfDay = useSelector((state: RootState) => state.search.terms.timeOfDay)
  const terms = useSelector((state: RootState) => state.search.terms)

  // Local term variables
  const [ activeMonth, setActiveMonth ] = useState<Date>()
  const [ activeDate, setActiveDate ] = useState<Date>()
  const [ activeTimeOfDay, setActiveTimeOfDay ] = useState<TimeOfDay>(TimeOfDay.All)

  // Calendar search variables
  const [ calendarPending, setCalendarPending ] = useState<boolean>(false)
  const [ calendarSlots, setCalendarSlots ] = useState<CalendarSlot[]>([])

  // Day search variables
  const [ dayPending, setDayPending ] = useState<boolean>(false)
  const [ daySlots, setDaySlots ] = useState<Appointment[]>([])

  // Day search pipes
  useEffect(() => {
    const daySearchObservable = daySearchSubject.pipe(
      tap(_ => setDayPending(true)),
      tap(_ => setDaySlots([])),
      debounceTime(500),
      switchMap(localTerms => from(api.search(localTerms)))
    )

    const daySearchSubscription = daySearchObservable.subscribe(slots => {
      setDaySlots(slots)
      setDayPending(false)
    })

    return () => {
      daySearchSubscription.unsubscribe()
    }
  }, [])

  // Calendar search pipes
  useEffect(() => {
    const calendarSearchObservable = calendarSearchSubject.pipe(
      tap(_ => setCalendarPending(true)),
      tap(_ => setCalendarSlots([])),
      debounceTime(500),
      switchMap(localTerms => {
        const { start, end } = getStartAndEndDateForTimeOfDay(localTerms.date, activeTimeOfDay)
        return from(api.calendarSearch(localTerms, start, end))
      })
    )

    const calendarSearchSubscription = calendarSearchObservable.subscribe(slots => {
      setCalendarSlots(slots)
      setCalendarPending(false)
    })

    return () => {
      calendarSearchSubscription.unsubscribe()
    }
  }, [activeTimeOfDay])

  // Initialize local terms
  useEffect(() => {
    setActiveMonth(storeDate)
    setActiveDate(storeDate)
    setActiveTimeOfDay(storeTimeOfDay)
  }, [storeDate, storeTimeOfDay])

  // Trigger calendar searches on local term changes
  useEffect(() => {
    if (activeMonth) {
      calendarSearchSubject.next({...terms, date: setDate(activeMonth, 1)})
    }
  }, [activeMonth, activeTimeOfDay, terms])

  // Trigger day search on local term change
  useEffect(() => {
    if (activeDate) {
      daySearchSubject.next({...terms, date: activeDate})
    }
  }, [activeDate, terms])

  const goBack = (saveChanges: boolean = false) => {
    props.history.replace(`/search/appointments${props.location.search}`)

    if (
      activeDate &&
      activeTimeOfDay &&
      saveChanges
    ) {
      dispatch(changeDate(activeDate))
      dispatch(changeTimeOfDay(activeTimeOfDay))
    }
  }

  const onReset = () => {
    setActiveMonth(new Date())
    setActiveDate(new Date())
    setActiveTimeOfDay(TimeOfDay.All)
  }

  return (
    <Popup
      title={<Translate id="calendar.title" />}
      onClose={() => goBack()}
      confirmAction={() => goBack(true)}
      confirmLabel={<Translate id="calendar.confirmTitle" data={{count: countTimeSlotsForTimeOfDay(daySlots, activeTimeOfDay)}} />}
      confirmPending={dayPending}
      cancelAction={() => onReset()}
      cancelLabel={<Translate id="calendar.cancelTitle" />}
    >
      {
        activeDate &&
        activeMonth &&
          <>
            <Calendar
              activeDate={activeDate}
              activeMonth={activeMonth}
              onSetActiveDate={setActiveDate}
              onSetActiveMonth={setActiveMonth}
              pending={calendarPending}
              slots={calendarSlots}
            />
            <TimeSelector
              time={activeTimeOfDay}
              onTimeChange={time => setActiveTimeOfDay(time)}
              pending={dayPending}
              slots={getSlotsForTimesOfDay(daySlots)}
            />
          </>
      }
    </Popup>
  )
}

export default withRouter(Time)
