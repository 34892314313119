import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App/App'
import * as Sentry from '@sentry/browser'
import { Firebase } from './utils'

Sentry.init({ dsn: 'https://ab3c2a66e281416284bf89389ba64b1c@sentry.mehilainen.fi/10' })

Firebase.initialize()

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
