import React, { useContext } from 'react'
import Loader from '../Loader/Loader'
import styled, { css, ThemeContext } from 'styled-components'

const fullWidthMixin = css`
  width: 100%;
`

const inverseMixin = css`
  background: ${props => props.theme.invertText};
  color: ${props => props.theme.selectionColor};
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
  font-size: 13px;
  font-weight: bold;
`

const transparentMixin = css`
  background: transparent;
  color: ${props => props.theme.selectionColor};
  font-size: 13px;
  font-weight: bold;
  border-radius: 0;
`

type ButtonMode = 'normal' | 'inverse' | 'transparent'

const StyledButton = styled.button<{ mode?: ButtonMode, fullWidth?: boolean }>`
  padding: 0 15px;
  background: ${props => props.theme.selectionColor};
  border-radius: 20px;
  color: ${props => props.theme.invertText};
  text-transform: uppercase;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;

  ${props => props.fullWidth && fullWidthMixin }
  ${props => props.mode === 'inverse' && inverseMixin}
  ${props => props.mode === 'transparent' && transparentMixin}
`

interface Props {
  label: string | JSX.Element
  onClick: () => void
  className?: string
  theme?: ButtonMode
  pending?: boolean
  fullWidth?: boolean
}

const Button: React.FC<Props> = (props) => {
  const theme = useContext(ThemeContext)

  return (
    <StyledButton
      className={props.className}
      onClick={props.onClick}
      mode={props.theme}
      fullWidth={props.fullWidth}
    >
      {
        props.pending ?
          <Loader
            color={props.theme === 'inverse' ? theme.selectionColor : theme.invertText}
            size="1x"
          />
          :
          props.label
      }
    </StyledButton>
  )
}

export default Button
