import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { History } from 'history'
import { RouterState, RouterAction, connectRouter } from 'connected-react-router'
import * as general from './general'
import * as search from './search'
import * as recipient from './recipient'
import * as confirmed from './confirmed'
import { GeneralState } from './general/general.reducer'
import { SearchState } from './search/search.reducer'
import { RecipientState } from './recipient/recipient.reducer'
import { ConfirmedState } from './confirmed/confirmed.reducer'

export { search, recipient }

export type RootAction =
  general.GeneralAction |
  search.SearchAction |
  recipient.RecipientAction |
  confirmed.ConfirmedAction |
  RouterAction

export interface RootState {
  router: RouterState
  general: GeneralState
  search: SearchState
  recipient: RecipientState
  confirmed: ConfirmedState
}

export const rootEpic = combineEpics(
  general.epics,
  search.epics,
  recipient.epics,
  confirmed.epics
)

export const createRootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    general: general.reducer,
    search: search.reducer,
    recipient: recipient.reducer,
    confirmed: confirmed.reducer
  })
