import { Appointment, TimeOfDay } from '../../../../types'
import { parseJSON, getHours, set, sub } from 'date-fns'
import { TimeOfDayMapping } from '../../../../utils'

export const countTimeSlotsForTimeOfDay = (slots: Appointment[], time: TimeOfDay): number =>
  slots.filter(slot => {
    const startingHour = getHours(parseJSON(slot.start))

    return (
      startingHour >= TimeOfDayMapping[time].min &&
      startingHour < TimeOfDayMapping[time].max
    )
  }).length

export const getSlotsForTimesOfDay = (slots: Appointment[]) =>
  Object.keys(TimeOfDayMapping).map(time => ({
    [time]: countTimeSlotsForTimeOfDay(slots, time as TimeOfDay) > 0 ? true : false
  })).reduce((acc, cur) => {
    return {...acc, ...cur}
  }, {})

export const getStartAndEndDateForTimeOfDay = (date: Date, time: TimeOfDay): {start: Date, end: Date} => {
  const startTime = set(date, { date: 1, month: date.getMonth(), hours: TimeOfDayMapping[time].min, minutes: 0, seconds: 0, milliseconds: 0 })
  const endTime = set(date, { date: 1, month: date.getMonth(), hours: TimeOfDayMapping[time].max, minutes: 0, seconds: 0, milliseconds: 0 })

  return {
    start: startTime,
    end: getHours(endTime) === 0 ? sub(endTime, { seconds: 1 }) : endTime
  }
}
