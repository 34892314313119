import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserMd,
  faCalendarAlt,
  faLocationArrow,
  faAngleRight,
  faDiagnoses,
  faComment,
} from '@fortawesome/pro-light-svg-icons'
import Button from '../../general/Button/Button'
import Accordion from '../../general/Accordion/Accordion'
import { Translate } from 'react-localize-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { iniateLoad, cancelAppointment, resetState } from '../../../state/confirmed/confirmed.actions'
import { RootState } from '../../../state'
import { format, parseJSON, isBefore, sub } from 'date-fns'
import Loader from '../../general/Loader/Loader'
import { locales } from '../../../locale/dateLocales'
import { openMap, addToCalendar } from '../../../services/native'
import { CSSTransition } from 'react-transition-group'
import { Subject, of } from 'rxjs'
import { switchMap, tap, take, filter } from 'rxjs/operators'
import Popup from '../../general/Popup/Popup'
import Header from './Header/Header'
import { typeInfoMapping, Timeslot } from '../../../utils'
import { Reservation, ObjectDetails, PractitionerDetails } from '../../../types'
import styled from 'styled-components'
import { darken } from 'polished'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${props => props.theme.invertText};
`

const StyledScroller = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const StyledIntro = styled.div`
  padding: 20px;
  line-height: 1.5;
`

const StyledSubtitle = styled.h2`
  font-size: 25px;
`

const StyledInfo = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`

const StyledInfoDetails = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: ${props => props.theme.primaryText};
`

const StyledInfoIcon = styled.div`
  margin-right: 20px;
  color: ${props => darken(0.1, props.theme.accentColor)};
`

const StyledInfoAdditionalDetails = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.secondaryText};
`

const StyledInfoActions = styled.div`
  padding: 20px 10px 0 20px;
  display: flex;
  flex-wrap: wrap;
`

const StyledInfoAction = styled(Button)`
  flex: 1 0 auto;
  height: auto;
  min-height: 40px;
  margin-right: 10px;
  margin-bottom: 10px;
`

const StyledInfoLink = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  text-align: left;
`

const StyledInfoText = styled.div`
  flex: 1;
  color: ${props => props.theme.selectionColor};
`

const StyledInfoArrow = styled.div`
  color: ${props => props.theme.borderColor};
`

const StyledAccordion = styled(Accordion)`
  border-bottom: 1px solid ${props => props.theme.borderColor};
`

const StyledActions = styled.div`
  padding: 0 20px;
`

const StyledError = styled.div`
  flex: 1;
  color: ${props => props.theme.errorColor};
  text-align: center;
  padding: 15px;
`

const StyledActionButton = styled(Button)`
  margin: 20px 0;
`

const StyledPopup = styled(Popup)`
  &.enter {
    opacity: 0;
    transform: scale(1.5);
  }
  &.enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 150ms ease-out, transform 150ms ease-out;
  }
  &.exit {
    opacity: 1;
    transform: scale(1);
  }
  &.exit-active {
    opacity: 0;
    transform: scale(1.5);
    transition: opacity 150ms ease-out, transform 150ms ease-out;
  }
`

const StyledCancel = styled.div`
  padding: 15px;
`

const isCancellable = (reservation: Reservation) =>
  isBefore(new Date(), sub(parseJSON(reservation.start), { minutes: 15 }))

const isInFuture = (date: Date) =>
  isBefore(new Date(), date)

const actionSubject = new Subject<{ mode: 'cancel' | 'reschedule', appointmentId: number, practitionerId: number, service?: number }>()
const confirmSubject = new Subject<boolean>()

const Appointment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const appointmentStore = useSelector((state: RootState) => state.confirmed.appointment.data)
  const appointmentPendingStore = useSelector((state: RootState) => state.confirmed.appointment.status === 'PENDING')
  const objectDetailsStore = useSelector((state: RootState) => state.confirmed.objectDetails.data)
  const objectDetailsPendingStore = useSelector((state: RootState) => state.confirmed.objectDetails.status === 'PENDING')
  const locationStore = useSelector((state: RootState) => state.confirmed.location.data)
  const locationPendingStore = useSelector((state: RootState) => state.confirmed.location.status === 'PENDING')
  const { appointmentId: appointmentIdParam, displayMode } = useParams<{ appointmentId: string, displayMode: string }>()
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [confirmMode, setConfirmMode] = useState<'cancel' | 'reschedule'>()
  const cancelPendingStore = useSelector((state: RootState) => state.confirmed.cancel.status === 'PENDING')
  const cancelErrorStore = useSelector((state: RootState) => state.confirmed.cancel.error)
  const cancelDoneStore = useSelector((state: RootState) => state.confirmed.cancel.status === 'FULFILLED')
  const personEmailStore = useSelector((state: RootState) => state.confirmed.person.email)
  const personEmailPendingStore = useSelector((state: RootState) => state.confirmed.person.status === 'PENDING')
  const reservedPrice = appointmentStore ? Timeslot.getReservedPrice(appointmentStore) : undefined
  const infoMapping = typeInfoMapping.find(mapping => mapping.id === reservedPrice?.timeslotTypeId)
  const isExternalAppointment = appointmentStore?.isExternalObject

  // Pipes for cancel and reschedule flow
  useEffect(() => {
    const actionObservable = actionSubject.pipe(
      tap(({ mode }) => setConfirmMode(mode)),
      tap(() => setConfirmVisible(true)),
      switchMap(({ mode, appointmentId, practitionerId, service }) => confirmSubject.pipe(
        take(1),
        tap(() => setConfirmVisible(false)),
        filter(confirm => confirm),
        switchMap(() => mode === 'cancel' ?
          of(dispatch(cancelAppointment.request(appointmentId)))
          :
          of(history.push(`/recipient/${practitionerId}?service=${service}&reschedule=${appointmentId}`, { direction: 'back' }))
        )
      ))
    )
    const actionSubscription = actionObservable.subscribe()

    return () => {
      actionSubscription.unsubscribe()
    }
  }, [dispatch, history])

  // Cancel success redirect
  useEffect(() => {
    if (cancelDoneStore && appointmentIdParam) {
      history.push(`/appointment/${appointmentIdParam}/cancel`)
    }
  }, [cancelDoneStore, appointmentIdParam, history])

  // Initial load
  useEffect(() => {
    if (typeof appointmentIdParam !== 'undefined') {
      dispatch(iniateLoad(parseInt(appointmentIdParam, 10)))
    }
  }, [appointmentIdParam, dispatch])

  const goBack = () => {
    dispatch(resetState())
    history.push('/search/reservations', { direction: 'back' })
  }

  const getPageTitle = () => {
    switch (displayMode) {
      case 'cancel':
        return <Translate id="confirmed.cancelledTitle" />
      case 'confirm':
        return <Translate id="confirmed.title" />
      default:
        return <Translate id="confirmed.viewTitle" />
    }
  }

  const loading = appointmentPendingStore || objectDetailsPendingStore || locationPendingStore || personEmailPendingStore

  const objectIcon = objectDetailsStore?.kind === 'ResourceDetails' ? faDiagnoses : faUserMd

  return (
    <StyledContainer>
      <Header
        title={getPageTitle()}
        showBack={displayMode !== 'confirm'}
        goBack={goBack}
      />
      <StyledScroller>
        {
          (
            displayMode === 'cancel' ||
            displayMode === 'confirm'
          ) &&
          <StyledIntro>
            <StyledSubtitle><Translate id="confirmed.subtitle" /></StyledSubtitle>
            {displayMode === 'cancel' && <Translate id="confirmed.cancellationMessage" />}
            {displayMode === 'confirm' && <Translate id="confirmed.confirmationMessage" data={{ email: `<strong>${personEmailStore ?? ''}</strong>` }} />}
          </StyledIntro>
        }
        {
          loading ?
            <Loader />
            :
            <Translate>
              {
                ({ activeLanguage }) =>
                  <>
                    <StyledInfo>
                      <StyledInfoDetails>
                        <StyledInfoIcon>
                          <FontAwesomeIcon
                            icon={objectIcon}
                            style={{
                              width: 23,
                              height: 23
                            }}
                          />
                        </StyledInfoIcon>
                        <NameAndRole objectDetails={objectDetailsStore} />
                      </StyledInfoDetails>
                    </StyledInfo>
                    {
                      !!infoMapping &&
                      <StyledInfo>
                        <StyledInfoDetails>
                          <StyledInfoIcon>
                            <FontAwesomeIcon
                              icon={infoMapping.icon}
                              style={{
                                width: 23,
                                height: 23
                              }}
                            />
                          </StyledInfoIcon>
                          <div>
                            <Translate id={infoMapping.title} />
                            {!!infoMapping.subtitle && <StyledInfoAdditionalDetails>
                              <Translate id={infoMapping.subtitle} />
                            </StyledInfoAdditionalDetails>}
                          </div>
                        </StyledInfoDetails>
                      </StyledInfo>
                    }
                    {
                      appointmentStore?.reservation?.reason &&
                      <StyledInfo>
                        <StyledInfoDetails>
                          <StyledInfoIcon>
                            <FontAwesomeIcon
                              icon={faComment}
                              style={{
                                width: 23,
                                height: 23
                              }}
                            />
                          </StyledInfoIcon>
                          <div>
                            {appointmentStore.reservation.reason}
                          </div>
                        </StyledInfoDetails>
                      </StyledInfo>

                    }
                    <StyledInfo>
                      <StyledInfoDetails>
                        <StyledInfoIcon>
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            style={{
                              width: 23,
                              height: 23
                            }}
                          />
                        </StyledInfoIcon>
                        <Translate
                          id="confirmed.date"
                          data={{
                            date: format(parseJSON(appointmentStore?.start ?? new Date()), 'PPP', { locale: locales[activeLanguage?.code] }),
                            start: <strong>{format(parseJSON(appointmentStore?.start ?? new Date()), 'p', { locale: locales[activeLanguage?.code] })}</strong>,
                            end: <strong>{format(parseJSON(appointmentStore?.end ?? new Date()), 'p', { locale: locales[activeLanguage?.code] })}</strong>
                          }}
                        />
                      </StyledInfoDetails>
                      {
                        displayMode !== 'cancel' &&
                        appointmentIdParam &&
                        appointmentStore &&
                        locationStore &&
                        isInFuture(parseJSON(appointmentStore.start)) &&
                        <StyledInfoActions>
                          <StyledInfoAction
                            onClick={() =>
                              addToCalendar({
                                calendarStartDate: appointmentStore.start,
                                calendarEndDate: appointmentStore.end,
                                calendarEventTitle: `${locationStore.locationName[activeLanguage.code]} - ${appointmentStore.objectDisplayName[activeLanguage.code]}`,
                                calendarEventNotes: '',
                                calendarEventAddress: `${locationStore.locationAddress[activeLanguage.code]}, ${locationStore.locationCity[activeLanguage.code]}`
                              })
                            }
                            label={<Translate id="confirmed.addToCalendarTitle" />}
                            theme="inverse"
                          />
                          {
                            isCancellable(appointmentStore) && !isExternalAppointment &&
                            <StyledInfoAction
                              onClick={() => actionSubject.next({ mode: 'reschedule', appointmentId: parseInt(appointmentIdParam, 10), practitionerId: appointmentStore.objectId, service: appointmentStore.objectPrimaryMedicalServiceId })}
                              label={<Translate id="confirmed.rescheduleTitle" />}
                              theme="inverse"
                            />
                          }
                        </StyledInfoActions>
                      }
                    </StyledInfo>
                    {
                      !infoMapping?.remote &&
                      <StyledInfo>
                        <StyledInfoLink
                          onClick={() =>
                            locationStore &&
                            displayMode !== 'cancel' &&
                            openMap(locationStore?.latitude, locationStore?.longitude)
                          }
                        >
                          <StyledInfoDetails>
                            <StyledInfoIcon>
                              <FontAwesomeIcon
                                icon={faLocationArrow}
                                style={{
                                  width: 23,
                                  height: 23
                                }}
                              />
                            </StyledInfoIcon>
                            <StyledInfoText>
                              {locationStore?.locationName[activeLanguage.code]}<br />
                              {locationStore?.locationAddress[activeLanguage.code]}<br />
                              {locationStore?.locationCity[activeLanguage.code]}
                            </StyledInfoText>
                            <StyledInfoArrow>
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                style={{
                                  width: 29,
                                  height: 29
                                }}
                              />
                            </StyledInfoArrow>
                          </StyledInfoDetails>
                        </StyledInfoLink>
                      </StyledInfo>
                    }
                    {
                      displayMode !== 'cancel' && !isExternalAppointment &&
                        objectDetailsStore && objectDetailsStore.kind === 'PractitionerDetails'
                        ? <TermsAndConditions practitionerDetails={objectDetailsStore} />
                        : null
                    }
                    <StyledActions>
                      {
                        displayMode !== 'cancel' &&
                        appointmentIdParam &&
                        appointmentStore &&
                        isCancellable(appointmentStore) &&
                        <>
                          {
                            cancelErrorStore &&
                            <StyledError><Translate id="confirmed.cancelFail" /></StyledError>
                          }
                          <StyledActionButton
                            fullWidth
                            pending={cancelPendingStore}
                            onClick={() => actionSubject.next({ mode: 'cancel', appointmentId: parseInt(appointmentIdParam, 10), practitionerId: appointmentStore.objectId })}
                            label={<Translate id="confirmed.cancelTitle" />}
                            theme="transparent"
                          />
                        </>
                      }
                      <StyledActionButton
                        fullWidth
                        onClick={goBack}
                        label={<Translate id="confirmed.bookings" />}
                        theme="normal"
                      />
                    </StyledActions>
                  </>
              }
            </Translate>
        }
      </StyledScroller>
      <CSSTransition
        in={confirmVisible}
        timeout={150}
        unmountOnExit
      >
        <StyledPopup
          title={confirmMode === 'cancel' ? <Translate id="confirmed.cancelPopupTitle" /> : <Translate id="confirmed.reschedulePopupTitle" />}
          onClose={() => confirmSubject.next(false)}
          confirmLabel={<Translate id="general.confirm" />}
          confirmAction={() => confirmSubject.next(true)}
          cancelLabel={<Translate id="general.cancel" />}
          cancelAction={() => confirmSubject.next(false)}
        >
          <StyledCancel>
            {
              confirmMode === 'cancel' ?
                <Translate id="confirmed.cancelPopupSubtitle" />
                :
                <Translate id="confirmed.reschedulePopupSubtitle" />
            }
          </StyledCancel>
        </StyledPopup>
      </CSSTransition>
    </StyledContainer>
  )
}

const NameAndRole = ({ objectDetails }: { objectDetails: ObjectDetails | null }) => (
  <Translate>
    {({ activeLanguage }) => (
      objectDetails ?
        <div>
          {objectDetails.displayName[activeLanguage.code]}
          {
            objectDetails.kind === 'PractitionerDetails' &&
            objectDetails.primaryMedSerName !== undefined &&
            <StyledInfoAdditionalDetails>{objectDetails.primaryMedSerName[activeLanguage.code]}</StyledInfoAdditionalDetails>
          }
        </div>
      : null
    )}
  </Translate>
)

const TermsAndConditions = ({ practitionerDetails }: { practitionerDetails: PractitionerDetails }) => (
  <Translate>
    {({ activeLanguage }) => (
      <>
        <StyledAccordion title={<Translate id="confirmed.termsOfCancellationTitle" />}>
          {practitionerDetails.cancellationPolicy[activeLanguage.code]}
        </StyledAccordion>
        <StyledAccordion title={<Translate id="confirmed.paymentMethodsTitle" />}>
          {practitionerDetails.paymentTerms[activeLanguage.code]}
        </StyledAccordion>
      </>
    )}
  </Translate>
)

export default Appointment
