import { createStore, applyMiddleware, Store } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { RootState, createRootReducer, rootEpic } from '../state'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'
import { persistStore, persistReducer } from 'redux-persist'
import persistConfig from './persist'

const epicMiddleware = createEpicMiddleware()

export default function configureStore(history: History) {
  const persistedReducer = persistReducer<RootState>(persistConfig, createRootReducer(history))

  let middleware = applyMiddleware(epicMiddleware, routerMiddleware(history))
  if (process.env.NODE_ENV ===  'development') {
    middleware = composeWithDevTools(middleware)
  }

  const store = createStore(
    persistedReducer,
    middleware
  ) as Store<RootState>

  const persistor = persistStore(store)

  epicMiddleware.run(rootEpic as any)

  return {
    store,
    persistor
  }
}
