import { createAsyncAction } from 'typesafe-actions'
import { Service, AppointmentType, User } from '../../types'

export const iniateLoad = createAsyncAction(
  'general/INITIAL_LOAD_REQUEST',
  'general/INITIAL_LOAD_SUCCESS',
  'general/INITIAL_LOAD_FAILURE'
)<void, void, Error>()

export const getUser = createAsyncAction(
  'general/GET_USER_REQUEST',
  'general/GET_USER_SUCCESS',
  'general/GET_USER_FAILURE'
)<void, User, Error>()

export const getServices = createAsyncAction(
  'general/GET_SERVICES_REQUEST',
  'general/GET_SERVICES_SUCCESS',
  'general/GET_SERVICES_FAILURE'
)<void, Service[], Error>()

export const getTypes = createAsyncAction(
  'general/GET_TYPES_REQUEST',
  'general/GET_TYPES_SUCCESS',
  'general/GET_TYPES_FAILURE'
)<void, AppointmentType[], Error>()
