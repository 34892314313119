import { Appointment, GroupedAppointment, TimeOfDay, PractitionerDetailsRaw, PractitionerDetails, CalendarSlot, I18n, AppointmentType, AppointmentPrice, ResourceDetails } from '../types'
import { AxiosResponse } from 'axios'
import { parseJSON, getHours, isAfter, closestTo } from 'date-fns'
import { TimeOfDayMapping } from '../utils'

export const extractResult = <T>(response: AxiosResponse<T>): T => response.data

function decodeAppointment(app: any): Appointment {
  return {
    ...app,
    prices: app.prices || []
  }
}

export function decodeAppointments(app: any[]): Appointment[] {
  return app.map(decodeAppointment)
}

export const filterPastAppointments = (appointments: Appointment[]): Appointment[] => {
  const now = new Date()
  return appointments.filter(appointment => isAfter(parseJSON(appointment.start), now))
}

export const filterFreeAppointments = (appointments: Appointment[]): Appointment[] =>
  appointments.filter(appointment => !appointment.isReserved)

const filterAppointments = (appointments: Appointment[], timeOfDay: TimeOfDay): Appointment[] =>
  appointments.filter(appointment => {
    const startingHour = getHours(parseJSON(appointment.start))

    return (
      startingHour >= TimeOfDayMapping[timeOfDay].min &&
      startingHour < TimeOfDayMapping[timeOfDay].max
    )
  })

const getPriceRange = (appointments: Appointment[]): number[] | null => {
  const prices = Array.from(new Set(// Use unique values
    appointments
      .filter(appointment => appointment.prices !== null)
      .map(appointment => appointment.prices!.map(price => price.price)) // Get individual prices
      .reduce((prev, cur) => { // Flatten array
        return prev.concat(cur)
      }, [])
      .sort((a, b) => a - b) // Numerical sort
  ))

  if (prices.length) {
    return prices
  } else {
    return null
  }
}

const getUniqueLocations = (appointments: Appointment[]) =>
  appointments
    .map(appointment => ({ id: appointment.location.locationId, name: appointment.location.locationName, distance: appointment.distance }))
    .sort((a, b) => a.distance - b.distance)
    .reduce((arr: { id: number, name: I18n, distance: number }[], item) =>
      arr.find(loc => loc.id === item.id) ? arr : arr.concat([item]),
      []
    )

const getShortestDistance = (appointments: Appointment[]) =>
    appointments
      .map(appointment => appointment.distance)
      .sort((a, b) => a - b)
      [0]

export const groupAppointments = (appointments: Appointment[], timeOfDay: TimeOfDay): GroupedAppointment[] =>
  Object.values(
    filterAppointments(appointments, timeOfDay)
      .reduce<{ [index: string]: Appointment[] }>((result, item) => ({
        ...result,
        [item.objectId]: [
          ...(result[item.objectId] || []),
          item
        ],
      }),
      {})
  )
  .map(groupedAppointments => (
    {
      practitioner: groupedAppointments[0].objectDisplayName,
      objectId: groupedAppointments[0].objectId,
      objectType: groupedAppointments[0].objectType,
      medicalService: groupedAppointments[0].objectPrimaryMedicalServiceName,
      distance: getShortestDistance(groupedAppointments),
      location: getUniqueLocations(groupedAppointments),
      price: getPriceRange(groupedAppointments),
      appointments: groupedAppointments
    }
  ))
  .sort(sortAppointments)

const sortAppointments = (a: GroupedAppointment, b: GroupedAppointment) => {
  const dateSort = parseJSON(a.appointments[0].start).getTime() - parseJSON(b.appointments[0].start).getTime()
  const distanceSort = a.distance - b.distance

  let priceSort
  if (a.price === null && b.price !== null) {
    priceSort = 1
  } else if (b.price === null && a.price !== null) {
    priceSort = -1
  } else if (a.price === null && b.price === null) {
    priceSort = 0
  } else {
    priceSort = a.price![0] - b.price![0]
  }

  return dateSort || distanceSort || priceSort
}

export const parsePractitionerDetails = (practitionerDetails: PractitionerDetailsRaw): PractitionerDetails => ({
  cancellationPolicy: practitionerDetails.cancellationPolicy,
  description: practitionerDetails.description,
  displayName: practitionerDetails.displayName,
  paymentTerms: practitionerDetails.paymentTerms,
  ...(practitionerDetails.hasPrimaryMedSer && { primaryMedSerName: practitionerDetails.primaryMedSerName }),
  ...(practitionerDetails.hasPrimaryLocation && { primaryLocationName: practitionerDetails.primaryLocationName })
})

export const parseResourceDetails = (data: any): ResourceDetails => ({
  displayName: data.displayName
})

export const parseNextAppointmentDate = (slots: CalendarSlot[], selectedDate: Date): Date => {
  const today = new Date()

  const filteredDates = slots
    .filter(slot => slot.hasFreeTimes)
    .map(slot => parseJSON(slot.date))
    .filter(date => isAfter(date, today))
    .filter(date => isAfter(date, selectedDate))

  if (filteredDates.length) {
    return closestTo(selectedDate, filteredDates)
  } else {
    throw new Error('No dates found')
  }
}

export const parseFreeCalendarDays = (slots: CalendarSlot[]) => slots.map(slot => ({...slot, hasFreeTimes: slot.hasTimeslots && !slot.areAllReserved}))

export const filterOnlineTypes = (types: AppointmentType[]): AppointmentType[] => types.filter(type => type.onlineBookingVisibility)

export const filterServicesFromPrices = (prices: AppointmentPrice[]): number[] =>
  Array.from(new Set(prices.map(price => price.medicalServiceId)))
