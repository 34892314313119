import React, { useState, useEffect, useCallback } from 'react'
import SearchBar from '../SearchBar/SearchBar'
import { Translate } from 'react-localize-redux'
import { faMapMarkerAlt, faLocationArrow } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  withRouter,
  RouteComponentProps
} from 'react-router-dom'
import { from, Subject, merge, of } from 'rxjs'
import { switchMap, tap, debounceTime, catchError } from 'rxjs/operators'
import * as google from '../../../../services/google'
import { GoogleAutocompletePrediction, Location } from '../../../../types'
import Loader from '../../../general/Loader/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../state'
import { changeLocation } from '../../../../state/search/search.actions'
import { getUserLocationObservable } from '../../../../services/native'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${props => props.theme.background};
`

const StyledScroller = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const StyledResults = styled.div`
  padding: 15px 30px;
`

const StyledResultButton = styled.button`
  display: flex;
  margin-bottom: 15px;
  color: ${props => props.theme.primaryText};
  align-items: center;
`

const StyledTerms = styled.div`
  text-align: left;
`

const StyledTerm = styled.span<{ secondary?: boolean }>`
  color: ${props => props.secondary ? props.theme.secondaryText : props.theme.primaryText };
  font-size: 15px;

  &:not(:last-of-type):after {
    content: ",";
    margin-right: 1ch;
  }
`

const StyledNearbyButton = styled.button`
  display: flex;
  margin-bottom: 15px;
  color: ${props => props.theme.primaryText};
  align-items: center;
`

const StyledIcon = styled.div`
  display: flex;
  color: ${props => props.theme.accentColor};
`

const StyledGeolocationError = styled.span`
  color: ${props => props.theme.accentColor};
`

const StyledTitle = styled.div`
  margin-bottom: 15px;
`

const StyledLoader = styled(Loader)`
  color: ${props => props.theme.accentColor};
`

const autocompleteSubject = new Subject<{query: string, location: Location}>()
const predictionSelectSubject = new Subject<{name: string, placeId: string}>()
const locationSelectSubject = new Subject<Location>()
const geolocationSubject = new Subject<void>()
const maxRecentLocations = 3

const LocationSearch: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch()
  const location = useSelector((state: RootState) => state.search.terms.location[0])
  const locations = useSelector((state: RootState) => state.search.terms.location)
  const [ query, setQuery ] = useState<string | null>(null)
  const [ pendingAutocomplete, setPendingAutocomplete ] = useState<boolean>(false)
  const [ results, setResults ] = useState<GoogleAutocompletePrediction[]>([])
  const goBack = useCallback(() => props.history.replace(`/search/appointments${props.location.search}`), [props.history, props.location.search])
  const [ geolocationState, setGeolocationState ] = useState<'inactive' | 'pending' | 'rejected'>('inactive')

  // React to query changes
  useEffect(() => {
    if (query) {
      autocompleteSubject.next({query, location})
    } else {
      setResults([])
    }
  }, [query, location])

  // Pipes for autocomplete
  useEffect(() => {
    const autocompleteObservable = autocompleteSubject.pipe(
      tap(_ => setPendingAutocomplete(true)),
      debounceTime(500),
      switchMap(searchVars =>
        searchVars.query.length > 3 ?
          from(google.autocompleteSearch(searchVars.query, searchVars.location))
          :
          of([])
      )
    )

    const autocompleteSubscription = autocompleteObservable.subscribe(autocompleteResults => {
      setPendingAutocomplete(false)
      setResults(autocompleteResults)
    })

    return () => {
      autocompleteSubscription.unsubscribe()
    }
  }, [])

  // Pipes for location selection
  useEffect(() => {
    const predictionSelectObservable = predictionSelectSubject.pipe(
      switchMap(prediction => from(google.getPlaceFromId(prediction.name, prediction.placeId)))
    )

    const locationAndPredectionSelectObservable = merge(locationSelectSubject, predictionSelectObservable)

    const locationSelectSubscription = locationAndPredectionSelectObservable.subscribe(newLocation => {
      dispatch(changeLocation(newLocation))
      goBack()
    })

    return () => {
      locationSelectSubscription.unsubscribe()
    }
  }, [dispatch, goBack])

  // Pipes for geolocation
  useEffect(() => {
    const geolocationObservable = geolocationSubject.pipe(
      tap(_ => setGeolocationState('pending')),
      switchMap(_ => getUserLocationObservable),
      switchMap(geolocation => from(google.getPlaceFromCoordinates(geolocation))),
      catchError(error => { throw error })
    )

    const geolocationSubscription = geolocationObservable.subscribe(
      newLocation => {
        dispatch(changeLocation(newLocation))
        goBack()
      },
      error => {
        setGeolocationState('rejected')
      }
    )

    return () => {
      geolocationSubscription.unsubscribe()
    }
  }, [dispatch, goBack])

  return (
      <StyledContainer>
        <Translate>
          {
            ({ translate }) =>
              <SearchBar
                query={query}
                icon={faMapMarkerAlt}
                placeholder={translate('location.title') as string}
                onChange={newQuery => setQuery(newQuery)}
                onClose={goBack}
              />
          }
        </Translate>
        <StyledScroller>
          <StyledResults>
            {
              pendingAutocomplete ?
                <Loader />
                :
                results.length ?
                  results.map(result =>
                    <StyledResultButton
                      key={result.place_id}
                      onClick={() => predictionSelectSubject.next({name: result.terms[0].value, placeId: result.place_id})}
                    >
                      <StyledIcon>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          style={{
                            width: 23,
                            height: 23,
                            marginRight: 20
                          }}
                        />
                      </StyledIcon>
                      <StyledTerms>
                        {
                          result.terms.map((term, index) =>
                            <StyledTerm
                              key={index}
                              secondary={index > 0}
                            >
                              {term.value}
                            </StyledTerm>
                          )
                        }
                      </StyledTerms>
                    </StyledResultButton>
                  )
                  :
                  <div>
                    <StyledNearbyButton onClick={() => geolocationSubject.next()}>
                      <StyledIcon>
                        <FontAwesomeIcon
                          icon={faLocationArrow}
                          style={{
                            width: 23,
                            height: 23,
                            marginRight: 20
                          }}
                        />
                      </StyledIcon>
                      {
                        geolocationState === 'pending' &&
                          <StyledLoader size="1x" />
                      }
                      {
                        geolocationState === 'rejected' &&
                          <StyledGeolocationError>
                            <Translate id="location.geolocationRejected" />
                          </StyledGeolocationError>
                      }
                      {
                        geolocationState === 'inactive' &&
                          <Translate id="location.searchNearby" />
                      }
                    </StyledNearbyButton>
                    <StyledTitle>
                      <Translate id="location.recentLocations" />
                    </StyledTitle>
                    {
                      locations
                        .slice(0, maxRecentLocations)
                        .map((recentLocation, index) =>
                          <StyledResultButton
                            key={index}
                            onClick={() => locationSelectSubject.next(recentLocation)}
                          >
                            <StyledIcon>
                              <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                style={{
                                  width: 23,
                                  height: 23,
                                  marginRight: 20
                                }}
                              />
                            </StyledIcon>
                            <StyledTerm>{recentLocation.name}</StyledTerm>
                          </StyledResultButton>
                      )
                    }
                  </div>
            }
          </StyledResults>
        </StyledScroller>
      </StyledContainer>
  )
}

export default withRouter(LocationSearch)
