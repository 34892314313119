import storage from 'redux-persist/lib/storage'
import { createFilter } from 'redux-persist-transform-filter'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import { createTransform } from 'redux-persist'
import { SearchState } from '../state/search/search.reducer'
import { parseJSON, isAfter, addHours } from 'date-fns'

const saveSubsetFilter = createFilter(
  'search',
  ['terms']
)

const dateTransform = createTransform<SearchState, SearchState>(
  null,
  outboundState => (
    {
      ...outboundState,
      terms: {
        ...outboundState.terms,
        date: isAfter(parseJSON(outboundState.terms.date), new Date()) ? parseJSON(outboundState.terms.date) : new Date()
      }
    }
  ),
  {
    whitelist: ['search']
  }
)

interface PerishableState extends SearchState {
  _expiresAt: Date
}

const expiryTransform = createTransform<PerishableState | {}, SearchState | {}>(
  inboundState => (
    {
      ...inboundState,
      _expiresAt: addHours(new Date(), 2)
    }
  ),
  outboundState => {
    const { _expiresAt, ...rest } = outboundState as PerishableState

    if (isAfter(parseJSON(_expiresAt), new Date())) {
      return { ...rest }
    } else {
      return {}
    }
  },
  {
    whitelist: ['search']
  }
)

export default {
  key: 'bh-appointments-customer',
  storage,
  whitelist: ['search'],
  transforms: [
    saveSubsetFilter,
    dateTransform,
    expiryTransform
  ],
  stateReconciler: autoMergeLevel2
}
