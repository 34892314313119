import React, { useState, useEffect } from 'react'
import {
  Link,
  useParams
} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { getPractitionerDetails } from '../../../../state/recipient/recipient.actions'
import { RootState } from '../../../../state'
import { Translate } from 'react-localize-redux'
import ObjectPicture from '../../../general/ObjectPicture/ObjectPicture'
import Loader from '../../../general/Loader/Loader'
import Description from './Description/Description'
import { fromEvent } from 'rxjs'
import { map } from 'rxjs/operators'
import styled, { css } from 'styled-components'

const StyledContainer = styled.div`
  padding-top: 70px;
  padding-top: calc(46px + max(24px, env(safe-area-inset-top)));
  background: ${props => props.theme.accentColor} url(${props => props.theme.headerImage}) bottom left;
  background-size: cover;
  color: ${props => props.theme.headerText};
`

const BackgroundVisibleMixin = css`
  z-index: 1;
  background-color: ${props => props.theme.accentColor};
`

const StyledBack = styled.div<{ backgroundVisible?: boolean }>`
  padding-top: 24px;
  padding-top: max(24px, env(safe-area-inset-top));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  transition: background-color 300ms ease-out;
  background-color: transparent;

  ${props => props.backgroundVisible && BackgroundVisibleMixin}
`
const StyledArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  color: ${props => props.theme.headerText};
`

const StyledLabel = styled.div`
  flex: 1;
  margin-right: 44px;
`

const StyledProfile = styled.div`
  display: flex;
  margin: 15px;
`

const StyledPicture = styled.div`
  display: flex;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  overflow: hidden;
`

const StyledDetails = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const StyledName = styled.h1`
  color: ${props => props.theme.headerText};
  margin-bottom: 0;
  font-size: 25px;
  font-weight: bold;
`

const StyledLocation = styled.div`
  color: ${props => props.theme.headerText};
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
`

const StyledDetail = styled.div`
  color: ${props => props.theme.headerText};
  font-size: 13px;
`

interface Props {
  scroller: HTMLElement | null
}

const Header: React.FC<Props> = ({ scroller }) => {
  const dispatch = useDispatch()
  const { practitionerId } = useParams<{ practitionerId?: string }>()
  const practitionerDetails = useSelector((state: RootState) => state.recipient.practitionerDetails.data)
  const pending = useSelector((state: RootState) => state.recipient.practitionerDetails.status === 'PENDING')
  const maxScroll = 50
  const [ backBackgroundVisible, setBackBackgroundVisible ] = useState<boolean>(false)

  // Pipes for scroll spy
  useEffect(() => {
    if (scroller !== null) {
      const scrollObservable = fromEvent<React.SyntheticEvent<HTMLElement>>(scroller, 'scroll').pipe(
        map(event => event.currentTarget.scrollTop),
        map(scroll => scroll > maxScroll)
      )

      const scrollSubscription = scrollObservable.subscribe(setBackBackgroundVisible)

      return () => {
        scrollSubscription.unsubscribe()
      }
    }
  }, [scroller])

  // Pipes for practitioner details
  useEffect(() => {
    if (practitionerId) {
      dispatch(getPractitionerDetails.request(parseInt(practitionerId, 10)))
    }
  }, [practitionerId, dispatch])

  return (
    <StyledContainer>
      <StyledBack backgroundVisible={backBackgroundVisible}>
        <Link
          to={
            {
              pathname: '/search/appointments',
              state: { direction: 'back' }
            }
          }
        >
          <StyledArrow>
            <FontAwesomeIcon
              icon={faCaretLeft}
              style={{
                width: 24,
                height: 24
              }}
            />
          </StyledArrow>
        </Link>
        <StyledLabel>
          <Translate id="recipient.title" />
        </StyledLabel>
      </StyledBack>
      {
        pending ?
          <StyledProfile>
            <Loader size="2x" />
          </StyledProfile>
          :
          <Translate>
          {
            ({ activeLanguage }) =>
              <>
                <StyledProfile>
                  <StyledPicture>
                    {
                      typeof practitionerId !== 'undefined' &&
                        <ObjectPicture objectId={parseInt(practitionerId, 10)} objectType={'practitioner'} />
                    }
                  </StyledPicture>
                  <StyledDetails>
                    <StyledName>{practitionerDetails?.details.displayName[activeLanguage.code]}</StyledName>
                    {
                      practitionerDetails?.details.primaryLocationName &&
                        <StyledLocation>{practitionerDetails?.details.primaryLocationName[activeLanguage.code]}</StyledLocation>
                    }
                    {
                      practitionerDetails?.details.primaryMedSerName &&
                        <StyledDetail>{practitionerDetails?.details.primaryMedSerName[activeLanguage.code]}</StyledDetail>
                    }
                    <StyledDetail>{practitionerDetails?.languages.map(language => language.language[activeLanguage.code]).join(', ')}</StyledDetail>
                    <StyledDetail>{practitionerDetails?.patientGroups.map(patientGroup => patientGroup.name[activeLanguage.code]).join(', ')}</StyledDetail>
                  </StyledDetails>
                </StyledProfile>
                <Description text={practitionerDetails?.details.description[activeLanguage.code]} />
              </>
          }
        </Translate>
      }
    </StyledContainer>
  )
}

export default Header
