import { FirebaseConfig, FirebaseAnalytics } from '../types'

declare global {
  interface Window {
    firebase?: {
      initializeApp?: (config: FirebaseConfig) => void
      analytics?: () => FirebaseAnalytics
    }
  }
}

let analyticsInstance: FirebaseAnalytics | undefined

export const initialize = () => {
  if (
    process.env.REACT_APP_FIREBASE_API_KEY &&
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN &&
    process.env.REACT_APP_FIREBASE_DATABASE_URL &&
    process.env.REACT_APP_FIREBASE_PROJECT_ID &&
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET &&
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID &&
    process.env.REACT_APP_FIREBASE_APP_ID &&
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  ) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
    window.firebase?.initializeApp?.(firebaseConfig)
    analyticsInstance = window.firebase?.analytics?.()
  }
}

export const trackEvent = (event: string, payload: unknown) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Firebase Tracking Debug', event, payload)
  } else {
    analyticsInstance?.logEvent(event, payload)
  }
}
