import React, { useState } from 'react'
import Popup from '../../../../general/Popup/Popup'
import { CSSTransition } from 'react-transition-group'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

const StyledDescriptionButton = styled.button`
  padding: 0 15px 15px 15px;
  width: 100%;
`

const StyledDescriptionContainer = styled.span`
  display: -webkit-box;
  color: ${props => props.theme.invertText};
  font-weight: 600;
  text-align: left;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  mask-image: linear-gradient(to top, transparent 10%, black);
  font-size: 15px;
  white-space: pre-wrap;
`

const StyledLongDescriptionContainer = styled.div`
  padding: 15px;
  color: ${props => props.theme.secondaryText};
  white-space: pre-wrap;
`

const StyledPopup = styled(Popup)`
  &.enter {
    opacity: 0;
    transform: scale(1.5);
  }
  &.enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 150ms ease-out, transform 150ms ease-out;
  }
  &.exit {
    opacity: 1;
    transform: scale(1);
  }
  &.exit-active {
    opacity: 0;
    transform: scale(1.5);
    transition: opacity 150ms ease-out, transform 150ms ease-out;
  }
`

interface Props {
  text?: string
}

const Description: React.FC<Props> = (props) => {
  const [ open, setOpen ] = useState<boolean>(false)

  return (
    <>
      <StyledDescriptionButton onClick={() => setOpen(true)}>
        <StyledDescriptionContainer>{props.text}</StyledDescriptionContainer>
      </StyledDescriptionButton>
      <CSSTransition
        in={open}
        timeout={150}
        unmountOnExit
      >
        <StyledPopup
          title={<Translate id="recipient.descriptionTitle" />}
          onClose={() => setOpen(false)}
        >
          <StyledLongDescriptionContainer>{props.text}</StyledLongDescriptionContainer>
        </StyledPopup>
      </CSSTransition>
    </>
  )
}

export default Description
