import React from 'react'
import SearchBarButton from './SearchBarButton/SearchBarButton'
import Tabs from './Tabs/Tabs'
import { Translate } from 'react-localize-redux'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const StyledHeader = styled.div`
  z-index: 1;
  height: 100px;
  height: 114px - max(14px, env(safe-area-inset-top));
  padding-top: 14px;
  padding-top: max(14px, env(safe-area-inset-top));
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  background: ${props => props.theme.accentColor};
`

const Header: React.FC = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  const tabs = [
    {
      label: <Translate id="tabs.appointments" />,
      path: `${match?.path}/appointments`
    },
    {
      label: <Translate id="tabs.reservations" />,
      path: `${match?.path}/reservations`
    }
  ]

  const onChangeTab = (value: number) => {
    history.replace(`${tabs[value].path}${location.search}`)
  }

  const getActiveTab = () => {
    const activeTabIndex = tabs.findIndex(tab => location.pathname === tab.path)

    if (activeTabIndex !== -1) {
      return activeTabIndex
    } else {
      return 0
    }
  }

  return (
    <StyledHeader>
      <SearchBarButton />
      <Tabs
        value={getActiveTab()}
        tabs={tabs.map(tab => tab.label)}
        onChange={onChangeTab}
      />
    </StyledHeader>
  )
}

export default Header
