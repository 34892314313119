import { SearchState } from './search.reducer'
import { Reservation } from '../../types'
import { isAfter, isBefore, isSameDay, parseJSON } from 'date-fns'

export const getFutureReservations = (state: SearchState): Reservation[] =>
  state.reservations.data
       .filter(reservation => isAfter(parseJSON(reservation.start), new Date()))
       .sort((a, b) => parseJSON(a.start).getTime() - parseJSON(b.start).getTime())

export const getPastReservations = (state: SearchState): Reservation[] =>
  state.reservations.data
       .filter(reservation => isBefore(parseJSON(reservation.start), new Date()))
       .sort((a, b) => parseJSON(b.start).getTime() - parseJSON(a.start).getTime())

export const hasReservationsForDate = (
  state: SearchState,
  date: Date,
  medicalServiceId: number
): boolean =>
  state.reservations.data
    .some(reservation => {
      const start = parseJSON(reservation.start)
      return (
        isSameDay(start, date) &&
        reservation.reservation?.medicalServiceId === medicalServiceId
      )
    })
