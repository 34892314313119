import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconDefinition,
  faCheck
} from '@fortawesome/pro-light-svg-icons'
import styled from 'styled-components'

const StyledRowButton = styled.button<{ selected: boolean }>`
  display: flex;
  width: 100%;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: ${props => props.selected ? 'bold' : 600};
`

const StyledIcon = styled.div`
  display: flex;
  margin-right: 15px;
  color: ${props => props.theme.primaryText};
`

const StyledLabel = styled.div`
  flex: 1;
  text-align: left;
`

const StyledAdornment = styled.div`
  display: flex;
  color: ${props => props.theme.accentColor};
`

interface Props {
  label: React.ReactElement | string
  selected: boolean
  onClick: () => void
  icon?: IconDefinition
}

const RowSelection: React.FC<Props> = ({label, selected, onClick, icon}) => {
  return (
    <StyledRowButton
      selected={selected}
      onClick={onClick}
    >
      {
        icon &&
          <StyledIcon>
            <FontAwesomeIcon
              icon={icon}
              style={{
                width: 18,
                height: 18
              }}
            />
          </StyledIcon>
      }
      <StyledLabel>{label}</StyledLabel>
      {
        selected &&
          <StyledAdornment>
            <FontAwesomeIcon
              icon={faCheck}
              style={{
                width: 18,
                height: 18
              }}
            />
          </StyledAdornment>
      }
    </StyledRowButton>
  )
}

export default RowSelection
