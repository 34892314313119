import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  background: #FFFFFF;
`

const StyledTab = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  color: ${props => props.selected ? props.theme.selectionColor : props.theme.secondaryText};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
`

const StyledIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 2px;
  background: ${props => props.theme.selectionColor};
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

interface Props {
  value: number
  tabs: JSX.Element[]
  onChange: (value: number) => void
}

const Header: React.FC<Props> = (props) => {
  const containerEl = useRef<HTMLDivElement>(null)
  const [ value, setValue ] = useState<number>(props.value)
  const [ position, setPosition ] = useState<{left: number, width: number}>({left: 0, width: 0})

  useEffect(() => {
    const currentTab = containerEl.current?.children[value]
    if (currentTab) {
      const { left, width } = currentTab.getBoundingClientRect()
      const parentRect = currentTab.parentElement?.getBoundingClientRect()
      const parentLeft = parentRect?.left ?? 0
      setPosition({left: left - parentLeft, width})
    }
  }, [value])

  const changeTab = (newValue: number) => {
    setValue(newValue)
    props.onChange(newValue)
  }

  return (
    <StyledContainer ref={containerEl}>
      {
        props.tabs.map((tab, index) =>
          <StyledTab
            data-value={index}
            key={`tab-${index}`}
            onClick={() => changeTab(index)}
            selected={value === index}
          >
            {tab}
          </StyledTab>
        )
      }
      <StyledIndicator
        style={{
          width: position.width,
          left: position.left
        }}
      />
    </StyledContainer>
  )
}

export default Header
