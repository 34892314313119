import React from 'react'
import Style from './ConfirmPopup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { Translate } from 'react-localize-redux'
import Button from '../../../general/Button/Button'

interface Props {
  show: boolean
  onClose: () => void
  onConfirm: () => void
  pending?: boolean
}

const ConfirmPopup: React.FC<Props> = (props) => {
  return (
    <div
      className={Style.background}
      style={props.show ? undefined : { display: 'none' }}
    >
      <div
        onClick={event => event.preventDefault()}
        className={Style.container}
      >
        <div className={Style.info}>
          <FontAwesomeIcon icon={faInfoCircle} size="2x" />
        </div>
        <div className={Style.header}>
          <h2 className={Style.title}>
            <Translate id="recipient.confirmTitle" />
          </h2>
        </div>
        <div className={Style.content}>
          <Translate id="recipient.confirmText" />
        </div>
        <div className={Style.actions}>
          <Button
            fullWidth
            className={Style.button}
            onClick={props.onClose}
            label={<Translate id="general.cancel" />}
            theme="normal"
          />
          <Button
            fullWidth
            className={Style.button}
            label={<Translate id="recipient.confirmButton" />}
            onClick={props.onConfirm}
            theme="transparent"
            pending={props.pending}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfirmPopup
