import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

interface Props {
  children: JSX.Element
}

export const ThemeGate: React.FC<Props> = ({ children }) => {
  const [ theme, setTheme ] = useState<any | null>(null)

  const getDymamicTheme = async () => {
    const { theme: importedTheme } = await import(`./${process.env.REACT_APP_THEME_KEY!}`)
    setTheme(importedTheme)
  }

  useEffect(() => {
    getDymamicTheme()
  }, [])

  return (
    theme ?
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
      :
      null
  )
}
